<div class="wapper">
  <div class="otp-code">
    <div class="form-otp">
      <span class="title">Verification Code</span>
      <form [formGroup]="formOtp" (ngSubmit)="onFormOtp()" class="content-otp">
        <div class="form-input">
          <div class="wrapper-input">
            <span>OTP</span>
            <input
              class="input-form"
              matInput
              required
              formControlName="otp_code"
            />
          </div>
          <div
            class="text-error"
            *ngIf="
              formOtp.get('otp_code')?.hasError('required') &&
              formOtp?.get('otp_code')?.touched
            "
          >
            OTP is required.
          </div>
        </div>
        <p>
          Didn’t receive the code in mailbox?
          <span *ngIf="isResend">Resend code after </span>
          <strong *ngIf="!isResend" class="resend-code" (click)="onResent()"
            >Resend code</strong
          >
          <strong *ngIf="isResend" class="resend-code"
            >{{ countdownTime$ | async }}s</strong
          >
        </p>
        <button [disabled]="formOtp.invalid">Verify</button>
        <button class="btn-back" (click)="backLogin()">Back</button>
      </form>
    </div>
  </div>
</div>
