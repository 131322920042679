<div class="wapper-login">
  <sod-wrapper-logo></sod-wrapper-logo>
  <div class="login">
    <div class="form-login" *ngIf="!isOtp">
      <span class="title">Login</span>
      <form
        [formGroup]="formLogin"
        (ngSubmit)="onFormLogin()"
        class="content-login"
      >
        <div class="form-input">
          <div class="wrapper-input">
            <span>Email</span>
            <input
              class="input-form"
              matInput
              required
              formControlName="username"
            />
          </div>
          <div
            class="text-error"
            *ngIf="
              formLogin.get('username')?.hasError('required') &&
              formLogin?.get('username')?.touched
            "
          >
            Username is required.
          </div>
          <div
            class="text-error"
            *ngIf="
              formLogin.get('username')?.hasError('username') &&
              formLogin?.get('username')?.touched
            "
          >
            Please enter a valid email address format.
          </div>
        </div>
        <div class="form-input">
          <div class="wrapper-input">
            <span>Password</span>
            <div class="input-password">
              <input
                class="input-form"
                type="{{ togglePassword }}"
                matInput
                required
                formControlName="password"
              />
              <div class="icon-password" (click)="onTogglePassword()">
                <img
                  *ngIf="togglePassword === 'password'"
                  src="/assets/svg/show-password.svg"
                  alt="show hide"
                />
                <img
                  *ngIf="togglePassword === 'text'"
                  src="/assets/svg/hide-password.svg"
                  alt="password hide"
                />
              </div>
            </div>
          </div>
          <div
            class="text-error"
            *ngIf="
              formLogin.get('password')?.hasError('required') &&
              formLogin?.get('password')?.touched
            "
          >
            Password is required.
          </div>
        </div>
        <button *ngIf="isOnline" type="submit" [disabled]="formLogin.invalid">
          Login
        </button>
      </form>
      <p *ngIf="isOnline" [routerLink]="['/forgot-password']">
        Forget password for Internet account?
      </p>
      <button
        *ngIf="!isOnline"
        class="btn login-departmental"
        (click)="departmentClick()"
      >
        Log In from Departmental Portal
      </button>
    </div>
    <sod-verification-code
      [idUser]="idUser"
      [(isOtp)]="isOtp"
      *ngIf="isOtp"
    ></sod-verification-code>
  </div>
</div>
