import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Roles } from '@sod/core/index';
import { Principal } from '@sod/core/models';
import { AuthService } from '@sod/services/auth.service';
import { PopupService } from '@sod/services/popup.service';
import { regex } from '@sod/shared';
import { LoginSite, UpdateToken, UpdateUserId } from '@sod/store/app.action';
import { catchError, map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  departmentClick() {
    throw new Error('Method not implemented.');
  }
  formLogin: FormGroup;
  isOnline: boolean = false;
  togglePassword: string = 'password';
  isOtp: boolean = false;
  idUser: number = 0;

  constructor(private _fb: FormBuilder,
    public authService: AuthService,
    private store: Store,
    private router: Router,
    private toast: PopupService) {
    this.formLogin = this._fb.group({
      username: ['', [Validators.required, Validators.pattern(regex.email)]],
      password: ['', [Validators.required]],
    });
  }
  onFormLogin() {
    this.authService.login({ ...this.formLogin.value }).pipe(
      tap(res => this.idUser = res),
      switchMap(res => this.authService.sendOtp(res)),
      catchError(() => this.toast.showToast("You do not have access to Seminar On Demand System. Please contact IT helpdesk", 'error').pipe(map(() => false)))
    )
      .subscribe(res => {
        this.isOtp = res;
      })

  }
  onTogglePassword() {
    if (this.togglePassword === 'password') {
      this.togglePassword = 'text'
    } else {
      this.togglePassword = 'password'
    }
  }

  protected activeRouter = inject(ActivatedRoute);
  ngOnInit(): void {
    this.isOnline = navigator.onLine

    const token = this.activeRouter.snapshot.queryParamMap.get('t')
    if (token) {
      const tokenDecrip = new Principal(token);
      this.store.dispatch(new UpdateToken(token))
      this.store.dispatch(new UpdateUserId(+tokenDecrip.UserId))
      if (tokenDecrip.IsRole(Roles.SysAdmin) || tokenDecrip.IsRole(Roles.Admin)) {
        this.router.navigate(['/portal-selection'])
      } else {
        this.store.dispatch(new LoginSite('learning'))
        this.router.navigate(['/learning'])
      }
    }
  }
}
