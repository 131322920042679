import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ErrorResponse } from '@sod/core/models';
import { AuthService } from '@sod/services/auth.service';
import { PopupService } from '@sod/services/popup.service';
import { LoginSite, UpdateToken, UpdateUserId } from '@sod/store/app.action';
import { catchError, debounceTime, iif, Observable, scan, Subject, switchMap, takeWhile, timer } from 'rxjs';

@Component({
  selector: 'sod-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrl: './verification-code.component.scss'
})
export class VerificationCodeComponent {
  formOtp: FormGroup;
  @Input() idUser: number = 0;
  @Input() isOtp: boolean= false;
  @Output('isOtpChange') change = new EventEmitter<boolean>();
  protected  countdownTime$! : Observable<number>;
  protected resentOtp$ = new Subject<number>()
  intervalId: any;
  isResend: boolean;
  constructor(private _fb: FormBuilder,
    public authService: AuthService,
    private toast: PopupService,
    private store: Store,
    private router: Router, private route: ActivatedRoute) {
    this.intervalId = '';
    this.isResend = false;
    this.formOtp = this._fb.group({
      otp_code: ['', [Validators.required, Validators.min(6)]],
    });
  }
  ngOnInit(): void {
    this.resentOtp$.pipe(
      debounceTime(60000),
      switchMap((x) =>  this.authService.sendOtp(x)),
    ).subscribe({
      next: res => {
        this.isResend = false;
      }, error: (res: HttpErrorResponse) => {
        const error = res.error as ErrorResponse;
        this.toast.showToast('You do not have access to Seminar On Demand System. Please contact IT Help-desk', 'error');
      },
      complete: () => {
        this.isResend = false;
        this.formOtp.reset()
      }
    })

  }
  onFormOtp() {
    if (!this.formOtp.valid) return;
    const otp = this.formOtp.value.otp_code;
    const userId = this.idUser;
    this.authService.validOtp(otp, userId).pipe(
      switchMap(res =>  res.isAdmin ? this.adminSite(res): this.learningSite(res)),
      catchError(() => this.toast.showToast('You do not have access to Seminar On Demand System. Please contact IT helpdesk', 'error')))
      .subscribe()
  }

  private adminSite(res: { token: string; userId: number; isAdmin: boolean; }) {
    this.store.dispatch(new UpdateToken(res.token))
    this.store.dispatch(new UpdateUserId(res.userId))
    this.formOtp.reset()
    return this.router.navigate(['/portal-selection']);
  }

  private learningSite(res: { token: string; userId: number; isAdmin: boolean; }) {
    this.store.dispatch(new UpdateToken(res.token))
    this.store.dispatch(new UpdateUserId(res.userId))
    this.formOtp.reset()
    this.store.dispatch(new LoginSite('learning'))
    return this.router.navigate(['/learning']);
  }
   getTimer () {
    return timer(0, 1000).pipe(
      scan(acc => --acc, 60), // 60s countdown
      takeWhile(x => x >= 0)
    );
  };
  onResent() {
    if(this.isResend)return;
    this.isResend = true;
    this.countdownTime$ = this.getTimer()
    this.resentOtp$.next(this.idUser);

  }
  backLogin(){
    this.change.emit(false)
  }
}
